.settingPage {
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0;
  }
}

.settingBox {
  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 0 25px;
  }

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: revert;
    margin: 18px 0;

    &-center {
      justify-content: center;
    }

    &-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0;
      font-family: "Roboto", sans-serif;
    }

    .MuiFormControlLabel-root {
      margin: 0 !important;
      gap: 5px;
      min-height: 35px;
    }

    input[type="number"] {
      background: #ffffff;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      min-height: 35px;
      width: 70px;
      padding: 5px 10px;
    }

    .fromGroup-chips {
      margin-top: 10px;
    }
  }

  &__part-inner {
    padding-left: 28px;
  }

  &__formBox {
    max-width: 415px;
    width: 100%;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      margin: 0 0 15px;
    }

    .fromGroup:not(:last-child) {
      margin-bottom: 30px;
    }

    .fromGroup input,
    .fromGroup .MuiInputBase-root {
      background: #fafafa;
    }

    fieldset {
      border: 1.2px solid #096dd9 !important;
    }
  }
}

.MuiSwitch-root {
  padding: 0;
  height: 20px;
  width: 40px;
  border-radius: 50px;

  .MuiButtonBase-root.MuiSwitch-switchBase {
    padding: 2px;
    background: transparent !important;
  }

  .Mui-checked + .MuiSwitch-track {
    opacity: 100% !important;
    border-radius: 50px;
    opacity: 100% !important;
  }
}

.formLogo {
  height: 34px;
}
.survetFormLogo {
  height: 15px;
}
.jotformLogo {
  height: 40px;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .catelogHeader {
    display: block !important;
    & > div:last-child {
      width: 100% !important;
      margin-top: 22px !important;
    }
  }
}
.settingBox__field-part {
  display: grid !important;
  grid-template-columns: 1fr 2fr !important;
}
.customSelectDrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  .customSelect {
    max-width: 300px;
    width: 100%;
  }
  & > .MuiInputBase-root {
    height: 35px;
  }
}

@media only screen and (max-width: 600px) {
  .settingBox {
    &__field {
      align-items: start;
      flex-direction: column;
      display: flex !important;
    }

    &__part-inner {
      padding-left: 18px;
    }

    &__field-title {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

/*Message Template Block Start*/
.message-templateBclobk {
  margin: 0;

  .fromGroup-chips {
    align-items: center;
    label {
      color: #000;
      margin-bottom: 0;
    }
    .MuiPaper-rounded {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  textarea {
    -webkit-appearance: value;
    -moz-appearance: value;
    appearance: value;
    resize: none;
  }
  .button {
    font-weight: 400;
  }
}
.stepperForm__footer {
  .button {
    font-weight: 400;
  }
}

.fromGroup {
  margin-bottom: 6px 0px;
  @media only screen and (max-width: 560px) {
    flex-direction: column;
    width: 100% !important;
  }

  .MuiInput-root {
    width: 100%;

    &::after,
    &::before {
      display: none;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    width: 100%;
    @media only screen and (max-width: 560px) {
      width: 100% !important;
    }

    &.Mui-focused {
      input {
        background: #ecf6ff;
        border: 1px solid #096dd9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
      }
    }
  }

  .MuiSelect-select {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: none;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  input {
    padding: 8px 12px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d9d9d9;
  }

  &-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    .MuiPaper-root {
      height: 40px;
      background: #fafafa;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      box-shadow: none;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      font-family: "Roboto", sans-serif;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      margin: 4px 0;
      @media only screen and (max-width: 560px) {
        height: 34px;
        font-size: 14px;
        margin: 2px 0;
      }
      // &:not(:last-child) {
      //   margin-right: 15px;
      // }
    }
  }

  .fromGroup-chip-active {
    // background: #ecf6ff;
    color: #096dd9;
    border: 1px solid #096dd9;
  }
}
.pageTemplate-top {
  margin: 20px 0px;
  // width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .addBtn {
    white-space: nowrap;
  }
}

.fromGroup-chip-active {
  border: 1px solid #096dd9 !important;
  color: #096dd9 !important;
}
.border-boxBlock {
  border: 1px solid #d9d9d9;
  background: #fafafa;
  padding: 6px;
  width: 110px;
  // margin: 0 auto;
}

.listingTable-catalog {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 22px;
  }

  &__title {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    // line-height: 32px;
    color: #000000;
    margin: 0;
  }

  .tableResponsive {
    box-shadow: none;
    padding: 5px;
  }

  table {
    border-spacing: 0 8px;

    tr {
      min-height: 48px;
      margin-bottom: 8px;
    }

    th,
    td {
      padding: 0px 16px;
      // text-align: left;
      font-family: "Roboto", sans-serif;
      white-space: pre;
      vertical-align: middle !important;
    }

    .MuiTableCell-head {
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 32px;
      color: rgba(0, 0, 0, 0.85);
      white-space: pre;
    }

    tbody {
      tr {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 4px !important;

        tr:nth-child(odd) {
          background: #ecf6ff;
        }
      }

      td {
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.85);
        vertical-align: middle !important;
      }
    }
  }
}

.border-boxBlockForm {
  border: 1px solid #d9d9d9;
  background: white;
  padding: 8px 11px;
  width: 240px;
  border-radius: 5px;
  font-size: larger;
}
.add-temp-btn {
  display: flex;
  gap: 15px;
  .survetFormLogo {
    margin-right: 5px !important;
  }
}
.checklistModal {
  .add-checklist {
    box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
    border-radius: 5px;
    padding: 10px;
    margin: 20px 0px;
  }
  .fromGroup {
    margin: 0px;
  }
  .css-kw13he-MuiDialogContent-root {
    width: 500px;
  }
  .css-knqc4i-MuiDialogActions-root {
    box-shadow: inset 0px 1px 0px #f0f0f0;
  }
  .css-ypiqx9-MuiDialogContent-root {
    // max-height: 700px;
  }
  .checklists {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 10px;
    row-gap: 10px;
  }
}

@media only screen and (max-width: 860px) {
  .add-temp-btn {
    margin-bottom: 15px;
    float: right;
  }
  .pageTemplate-top {
    display: block;
  }
  .checklistModal {
    .css-kw13he-MuiDialogContent-root {
      width: 375px;
    }
  }
}

@media only screen and (max-width: 560px) {
  .form-tabs {
    margin-bottom: 10px;
  }
}

.forms-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
  padding-bottom: 20px;
  @media only screen and (max-width: 1200px) {
    display: block;
  }
}
.formAction-buttons {
  justify-content: flex-end;
  position: relative;
  display: flex;
  text-align: right;
  gap: 10px;
  margin-bottom: 2px;
  button {
    gap: 5px;
    align-items: center;
  }
  img {
    margin-bottom: 4px;
  }
  @media only screen and (max-width: 1200px) {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  @media only screen and (max-width: 425px) {
    display: block;
    button {
      margin: 5px;
    }
  }
}
