.boardTemplate {
  padding: 32px 15px;
  height: calc(100vh - 80px);
  overflow: auto;
  position: relative;
  // overflow-x: hidden;
}
.sidePanel {
  background-color: #ecf6ff;
  min-height: calc(100vh - 80px);

  overflow: auto;
  &__closeBtn {
    display: none;
    @media (max-width: 991px) {
      display: block;
      margin: 10px 10px 0 auto;
      border: none;
      background: transparent;
    }
  }
  &_boards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &_createBtn {
    width: 100%;
    border: 1px solid rgb(9, 109, 217);
    white-space: nowrap;
    background-color: white !important;
    color: rgb(9, 109, 217) !important;
    text-transform: capitalize !important;
    font-weight: 700;
    font-size: 16px;
    // margin: 32px;
  }
}
.sidePannelContainer {
  background: transparent;
  box-shadow: none !important;
  width: 100%;
  padding: 10px 10px;
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0px;
  flex-direction: column;

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    width: 100%;
    // max-height: 200px;
    // overflow-y: auto;
  }

  .items {
    padding: 0px 20px 0px 63px;
  }
  .itemsCustom {
    padding: 0px 20px 0px 69px;
  }
}
.itemBtn {
  background: transparent;
  border: none;
  width: 100%;
  font-size: 16px;
  padding: 2px 30px;
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
}
// .itemBtnIntake {
//   padding: 8px 80px 8px 33px !important;
// }
.itemBtn:hover {
  background-color: #adcff3;
  border-radius: 4px;
}

.itemBtnProject {
  padding: 0px 20px 0px 68px !important;
}
.itemDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  // padding: 10px 65px 10px 37px;
  font-weight: 600;
  font-size: 18px;
}

.activeBtn {
  background-color: #096dd94f;
  border-radius: 4px;
  // font-weight: 600;
  color: #096dd9;
}
.itemBottomDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 65px 10px 37px;
  justify-content: flex-start;
  font-weight: 700;
}
.bottomDiv {
  background: transparent;
  box-shadow: none !important;
  margin-right: auto;
  overflow: auto;
  position: sticky;
  top: 100%;
}

// .splitter-layout {
//   height: calc(100% - 80px);
//   @media (max-width: 1576px) {
//     height: calc(100% - 64px);
//   }
//   .layout-pane-primary {
//     background-color: rgb(236, 246, 255);
//     & > div {
//       height: 100%;
//     }
//     @media (max-width: 1576px) {
//       min-width: 220px;
//     }
//     @media (max-width: 767px) {
//       min-width: 140px;
//     }
//   }
//   .boardTemplateSide {
//     width: 100%;
//   }
//   .cardBlock {
//     height: calc(100vh - 225px);
//   }
// }

.MuiButtonBase-root {
  line-height: 24px;
}

.MuiTypography-h5 {
  line-height: 32px;
}

.Draggable-items {
  width: 100%;
  .innerTabs__item2 {
    margin-bottom: 5px;
    button {
      font-size: 16px;
      line-height: normal;
      display: flex;
      align-items: flex-start;
      line-height: 25px;
      // word-break: break-word;
      &.active {
        color: #096dd9;
        font-weight: 600;
        background-color: rgba(9, 109, 217, 0.3098039216);
        border-radius: 4px;
        &::after {
          opacity: 0;
        }
      }
    }
  }
}

.board {
  width: 100%;
  // height: calc(100vh - 64px);
  display: flex;
  .sideDrawer {
    position: fixed;
    width: 250px;
    height: calc(100vh - 80px);
    overflow: auto;
    background-color: rgb(236, 246, 255);
    display: sticky;
    left: -250px;
    transition: 0.3s;
    &.open {
      left: 0;
    }
    @media (max-width: 991px) {
      left: -280px;
      position: absolute;
      z-index: 1;
      width: 280px;
    }
    .open {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .boardPanel {
    transition: 0.3s;
    width: 100%;
    margin-left: auto;
  }
  .sideDrawer.open ~ .boardPanel {
    width: calc(100% - 250px);
    @media (max-width: 991px) {
      width: 100%;
    }
  }
}
.runs {
  // position: relative;
  .runs-filters {
    top: 23px;
    position: absolute;
    right: 17px;
    @media (max-width: 1024px) {
      position: unset;
    }
  }
}
