.create-new-board {
  width: 100%;
  padding: 30px 30px 0px;
  .tab-section {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .innerTabs {
      width: auto;
      height: auto;
      overflow: hidden;
    }
  }
  .create-new-board-inner {
    padding-bottom: 30px;
  }
  .create-new-board-footer {
    display: flex;
    justify-content: right;

    text-align: center;
    .cancel {
      background-color: #fff !important;
      color: #096dd9 !important;
      border: 1px solid #096dd9;
    }
    button {
      line-height: normal;
      padding: 7px 15px;
      font-size: 13px;
    }
  }
  .innerTabs {
    gap: 25px;
    &__item button {
      font-size: 18px;
      padding: 0;
      cursor: pointer;
    }
  }
}
.border-none {
  input,
  fieldset,
  input:focus {
    border: 0px !important;
    outline: none;
    background: none !important;
  }
}
.checklist-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: 80px;
    background-color: rgb(9, 109, 217);
    color: white;
    border: 0px;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
  }
}
.automationTabbing {
  margin-bottom: 30px;
  &__inner {
    list-style-type: none;
    margin: 0;
    padding: 15px;
    //max-width: 275px;
    width: 275px;
    height: 350px;
    border: 1px solid #ccc;
    border-radius: 6px;
    ul {
      padding: 0;
      margin: 0;
    }

    &__items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .btn {
        border: none;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        transition: 0.2s;
        svg {
          height: 16px;
          width: 16px;
        }
        &.btnDelt {
          svg {
            fill: rgb(9, 109, 217);
          }
        }
      }
      .current {
        display: flex;
        border-bottom: 1px solid transparent;
        &:hover {
          border-bottom: 1px solid #096dd9;
          color: #096dd9;
        }
      }
      .btnTab {
        display: flex;
        border-bottom: 1px solid transparent;

        &__current {
          border-bottom: 1px solid #096dd9;
          color: #096dd9;
        }

        &:hover {
          border-bottom: 1px solid #096dd9;
          color: #096dd9;
        }
      }
    }
  }
  .addBtn {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    margin-top: 20px;
    cursor: pointer;
    svg {
      height: 16px;
      width: 16px;
    }
  }
  &__detail {
    width: 100%;
    h4 {
      margin: 0;
      font-size: 18px;
    }
  }
}
.customToggler {
  margin: 0;
  gap: 10px;
}

.boardLargeModel .MuiDialog-paper {
  min-width: 750px;
  width: 100%;

  @media only screen and (max-width: 991px) {
    min-width: 0px;
  }
}

.ml-0 {
  margin-left: 0 !important;
}

.dropdowncard {
  padding: 6px 10px;
  border-radius: 3px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 0 10px;
  .darkLabel {
    font-weight: 700;
    font-size: 14px;
    min-width: 50px;
  }
}

.DashboardHead {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 10px;
}
.stage {
  margin: 0px;
  max-height: 650px;
  overflow: auto;
}
@media only screen and (max-width: 991px) {
  .DashboardHead {
    margin: 0;
    margin-top: 42px;
  }
}
.ptb-15 {
  padding: 15px 0px;
}

.stageDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-right: 15px;
}
.permission-box {
  display: flex;
  margin: 2px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 3px 8px;
  border-radius: 5px;
  padding: 18px;
  width: max-content;
  .css-1f2kvjf-MuiFormControlLabel-root {
    gap: 5px;
    margin: 0px;
  }
}
