.groupBooking {
  .largeModel__footer {
    display: flex;
    justify-content: space-between !important;
  }
  .largeModel__stepperHead .MuiStep-root {
    flex-basis: 140px;
  }
}

.weekdays-selection {
  display: flex;
  gap: 10px;
}

.weekday-item {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f1f1f1;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.weekday-item.selected {
  background-color: #2196f3;
  color: #fff;
}

.info-section {
  margin-top: 20px;
  padding: 10px;
}

.info-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.info-title {
  font-weight: 500;
  margin-bottom: 5px;
}

.info-details {
  font-size: 14px;
}

.groupBooking-step1 {
  .css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 9.5px 14px;
  }
}

.tableBody {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
