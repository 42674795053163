//control schedule popup window
.schedule-detail-model {
  .MuiDialog-paper {
    max-width: 770px;
    padding: 0 40px;
    .largeModel__head {
      // background: #ffffff;
      border-bottom: 1px solid #dee2e6;
      padding: 12px 14px 8px 12px;
      .largeModel__title {
        color: #fff; // #212529;
        font-size: 18px;
        font-weight: 500;
      }
      .largeModel__closeBtn {
        background-color: transparent !important;
        color: #fff; //#212529 !important;
      }
    }
    .largeModel__body {
      .bookingModel {
        // padding: 20px;
        .bookingModel__form {
          margin-top: 15px;
          .userList-name {
            span {
              font-size: 15px;
              font-weight: bold;
            }
            .userSmallCircle {
              height: 35px;
              width: 35px;
            }
          }
          .MuiGrid-item {
            padding-top: 5px;
          }
          .fromLabel {
            color: #000;
            font-size: 16px;
            font-weight: bold;
          }
          .fromGroup-chips {
            .MuiPaper-root {
              height: 40px;
              font-size: 14px;
              line-height: normal;
            }
          }
          form {
            .MuiGrid-item {
              input {
                height: auto;
                padding: 6.5px 12px;
              }
              button {
                margin-right: 10px;
                line-height: normal;
                padding: 5px 14px;
              }
            }
            .MuiGrid-item.MuiGrid-grid-xs-12 {
              &:first-child {
                padding-top: 0 !important;
                .fromGroup {
                  margin-bottom: 20px;
                }
              }
            }
          }
        }
      }
    }
    .largeModel__footer {
      justify-content: space-between !important;
      .button {
        padding: 7px 15px;
        height: auto;
        font-size: 15px;
        line-height: normal;
        font-weight: normal;
      }
    }
  }
}

/* Read Documentation at
  https://ej2.syncfusion.com/react/documentation/schedule/scheduler-styling
  note: 
  - worked: use default, no customization. 
  - worked: use darker gray, use non-white color worked. 
  - not working: use darker grey, use white color for working hours. 
*/
.control-section {
  .e-schedule {
    min-height: calc(100vh - 330px);
    overflow-y: auto;
    .template-wrap {
      width: 100%;
    }

    //view: day, week
    .e-vertical-view {
      .e-work-cells {
        background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    //view: timeline day, timeline week
    .e-timeline-view {
      .e-work-cells {
        background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    //view: month
    .e-month-view {
      .e-work-cells {
        // background: rgb(238, 238, 238) !important;
        &.e-work-hours {
          background: #6bb283 !important;
        }
      }
    }

    // //view: timeline month
    // .e-timeline-month-view {
    //   .e-work-cells {
    //     // background: rgb(238, 238, 238) !important;
    //     &.e-work-hours {
    //       background: #fff; // #096dd9 !important;
    //     }
    //   }
    // }
  }
}

//make timeline view (day, week, month) looks nice!
// .schedule-control-section {
//   .e-schedule {
//     .e-timeline-view .e-day-view .e-agenda-view {
//       .e-resource-column-wrap {
//         table td {
//           padding: 0;
//           div {
//             width: 100%;
//           }
//         }
//       }
//     }
//   }
// }
// .e-block-appointment {
//   background: #d0d0d5;
// }
// .e-timeline-view {
//   .e-resource-cells {
//     height: 65px;
//   }
// }
// }
// }

.justify-content-between {
  justify-content: space-between !important;
}

.fromGroup-repeatDay {
  display: flex;
  justify-content: space-between;
  div {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    background-color: #eee;
    text-align: center;
    line-height: 30px;
  }
  .fromGroup-dayName {
    background-color: #096dd9;
    color: #fff;
  }
}

.check-AuthBlock {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-weight: 700;
  input {
    margin-right: 16px;
  }
}

.datepickerBlock {
  width: 140px;
  label,
  legend {
    display: none;
  }
  .MuiIconButton-edgeEnd {
    background-color: transparent !important;
    svg {
      color: #096dd9;
      font-size: 20px;
    }
  }
  input {
    border: 0;
    font-size: 14px;
    margin: 0;
  }
  .MuiInputAdornment-positionEnd {
    margin-left: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    top: 0;
  }
}
.deleteSchedule.schedule-detail-model .MuiDialog-paper {
  padding: 0;
}
.checkBoxEdit {
  margin-bottom: 18px;
  label {
    margin-left: 0 !important;
    span {
      color: #000;
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.flexNowrap {
  flex-wrap: nowrap !important;
  @media only screen and (max-width: 991px) {
    flex-wrap: wrap !important;
  }
}
.optionsMenu {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.m-15 {
  margin: 15px;
}
.booking-heatmap {
  .availabilityStatus {
    flex-direction: row;
    background: none;
    flex-wrap: nowrap;
    padding: 0;
    margin-bottom: 10px;
    max-width: none;
    // width: 100%;
  }
  .sliderAnimation___300FY {
    display: flex;
    gap: 15px;
  }
}

.control-wrapper-height {
  .e-table-container {
    transform: scale(var(--zoom-level));
    transform-origin: top left; /* Adjust based on your requirement */
    transition: transform 0.2s; /* Smooth transition */
  }
  .e-content-wrap {
    height: calc(400px * var(--zoom-level)) !important;
    transition: height 0.2s;
  }
  // .e-resource-column-wrap {
  //   height: calc(400px * var(--zoom-level)) !important;
  //   transition: height 0.2s;
  // }
}

.control-wrapper {
  .e-schedule .e-timeline-view .e-appointment,
  .e-schedule .e-timeline-month-view .e-appointment {
    height: auto !important;
  }
}
